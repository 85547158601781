<template>
  <v-container fluid>
    <v-card>
      <UserAuthForm
        v-if="user"
        ref="userAuthForm"
        type="Login"
        v-model="user"
        @submitForm="loginUser"
      />
    </v-card>

    <v-card class="my-3 px-3">
      <div class="my-3">
        <v-btn text link to="/user/forgotpassword"
          >Forgot Password?</v-btn
        >
      </div>
      <div class="my-3">
        <v-btn text link to="/user/register">Not Registered?</v-btn>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import UserAuthForm from '@/components/auth/UserAuthForm';

export default {
  components: {
    UserAuthForm,
  },
  data() {
    return {
      user: null,
    };
  },
  methods: {
    loginUser(user) {
      this.$store.dispatch('misc/setLoading', true);
      this.$store
        .dispatch('loginUser', user)
        .then(response => {
          this.reset();
          var snack = {
            text: response.data.msg,
            color: 'success',
          };
          this.$store
            .dispatch('misc/initApp', this.$route)
            .then(response => {
              this.$store.dispatch('misc/setSnack', snack);
              // this.$router.push({ name: 'General' });
            })
            .catch(error => {
              this.$store.dispatch('misc/setSnack', {
                text: error.response.data.msg,
                color: 'error',
              });
            })
            .finally(() => {
              this.$store.dispatch('misc/setLoading', false);
              this.$router.push({ name: 'General' });
            });
        })
        .catch(error => {
          console.log(error);
          console.log(error.response.data.msg);
          var snack = {
            text: error.response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
        });
    },
    reset() {
      this.$refs.userAuthForm.reset();
      this.user = this.emptyUser();
    },
  },
  computed: {
    ...mapGetters(['emptyUser', 'currentUser']),
  },
  mounted() {
    this.user = this.emptyUser();
  },
};
</script>

<style></style>
